import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { Box, CircularProgress, TextField} from '@mui/material';
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { NFTStorage, File } from "nft.storage";
import fs from 'fs';
import dotenv from 'dotenv';
import FadeIn from 'react-fade-in';
import { SocialIcon } from 'react-social-icons';
import "./styles.css";
import ConnectButton from './ConnectButton.js';
import axios from 'axios';
import logo from './logo.png'


dotenv.config()

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function App() {
  const API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDBhYjA5MEJGY2U2YjI5N2EzOTBhZDg2ZmUzNkFBOThhOEQxRGExMzMiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3ODE5NTkzMzE3OSwibmFtZSI6ImFzZCJ9.N6hYiCP_m_8CSGna4hxPo5E1oW7-xZOVI9OJw_EfI9I"
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState("")
  const data = useSelector((state) => state.data);
  const [mintAmount, setMintAmount] = useState(1);
  const [count, setCount] = useState(0)
  const [image, setImage] = useState()
  const [tokenURL, setTokenURL] = useState('');
  const [inputText, setInputText] = useState(' ')
  const [imageNFT, setImageNFT] = useState(null);
  const [NFTname, setNFTname] = useState('')
  const [feedback, setFeedback] = useState('')
  const [claimingNft,setClaimingNft] = useState(false)
  const [connectText, setConnectText] = useState('CONNECT')
  const [success, setSuccess] = useState()
  const [tokenURLs, setTokenURLs] = useState()
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [metadataURL, setMetadataURLs] = useState()
  const [pdfURL, setPdfURL] = useState()
  const [confirmText, setConfirmText] = useState('CONFIRM')
  
  const[started, setStarted] = useState(false)
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [fileName, setFileName] = useState('');
  const [videoURL, setVideoURL] = useState();
  const [video, setVideo] = useState('');
  const [pdf, setPdf] = useState('');
  const [pdfIPFSURL, setPdfIPFSURL] = useState('');
  const [pdfTokenizationRecord, setPdfTokenizationRecord] = useState('');
  const [pdfTokenizationRecordURL, setPdfTokenizationRecordURL] = useState('');
  const [landRegistry, setLandRegistry] = useState('');
  const [landRegistryURL, setLandRegistryURL] = useState('');
  const [propertyDeed, setPropertyDeed] = useState('');
  const [propertyDeedURL, setPropertyDeedURL] = useState('');
  const [initialPrice, setInitialPrice] = useState('');
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  
  
  
  function handlePropertyDeed(event) {
    const file = event.target.files[0];
    setFileName(file.name);
    setPropertyDeed(event.target.files[0]); // set the pdf state to the uploaded file
    // call the uploadPDF function here
    setIsFileUploaded(false); // reset the isFileUploaded state to false
  }

  function handleLandRegistry(event) {
    const file = event.target.files[0];
    setFileName(file.name);
    setLandRegistry(event.target.files[0]); // set the pdf state to the uploaded file
    // call the uploadPDF function here
    setIsFileUploaded(false); // reset the isFileUploaded state to false
  }

  function handleTokenizationRecord(event) {
    const file = event.target.files[0];
    setFileName(file.name);
    setPdfTokenizationRecord(event.target.files[0]); // set the pdf state to the uploaded file
    // call the uploadPDF function here
    setIsFileUploaded(false); // reset the isFileUploaded state to false
  }
  


  function handleVideoUpload(event) {
    const uploadedFile = event.target.files[0];
    const uploadedURL = URL.createObjectURL(uploadedFile);
    setVideo(uploadedFile)
    setVideoURL(uploadedURL);
    setIsFileUploaded(false); // reset the isFileUploaded state to false
  }

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePriceChange = (event) => {
    setInitialPrice(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleBasePriceChange = (event) => {
    setBasePrice(event.target.value);
  };
  

  const startButton = () => {
    setStarted(true);
  }
  

  const connectBut = () => {
    setIsConnected(true);
    setConnectText('DISCONNECT')
  };

  const disconnectBut = () => {
    setIsConnected(false);
    setConnectText('CONNECT')
  };

  const handleChange = async (event) => {
    setImage(event.target.files[0]);
  
  };

  const buttonClassName = landRegistry ? 'custom-file-input uploaded' : 'custom-file-input';
  const buttonClassName2 = videoURL ? 'custom-video-input uploaded' : 'custom-video-input';
  const buttonClassName3 = propertyDeed ? 'custom-file-input3 uploaded' : 'custom-file-input3';
  const buttonClassName4 = pdfTokenizationRecord ? 'custom-file-input4 uploaded' : 'custom-file-input4';

  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const handleUploadsAndMint = async () => {
    if (!propertyDeed || !video || !landRegistry || !pdfTokenizationRecord ) {
      return;
    }
    setClaimingNft(true);
    try {
      setIsLoadingButton(true);
  
      const [
        propertyDeedResult,
        tokenizationRecordResult,
        landRegistryResult,
        metadataURLResult,
      ] = await Promise.all([
        uploadPDF_PropertyDeed(),
        uploadPDF_TokenizationRecord(),
        uploadPDF_LandRegistry(),
        uploadVideo(),
      ]);
  
      setIsLoadingButton(false);
  
      // Call the mint function with the metadataURLResult
      mint(metadataURLResult);
    } catch (error) {
      console.error('Error uploading files:', error);
      setIsLoadingButton(false);
    }
  };
  
  
  


  
  
  const uploadPDF_PropertyDeed = async () => {
    if (!propertyDeed) {
      alert('Please upload the correct property deed')
      return;
    }
    setConfirmText('PLEASE WAIT')
    setIsLoadingButton(true);
    const client = new NFTStorage({ token: API_KEY });
    setNFTname(inputText);
    const metadataObjects = [];
    
    const metadata = await client.store({
      name: name, 
      description: 'Property deed of ' + name ,
      image: new File(
        [propertyDeed],
        'propertyDeed.pdf',
        { type: 'application/pdf' }
      ),attributes: [
        {
          trait_type: 'BasePrice',
          value: basePrice,
        },
        {
          trait_type: 'Category',
          value: 'Bué Fixe',
        },
      ],
    });
    
    metadataObjects.push(metadata);
    let metaURL = metadata.data.image.href
    metaURL = metaURL.replace("ipfs://", "https://ipfs.io/ipfs/")
    console.log(metaURL)
    setPropertyDeedURL(metaURL)
    setIsLoadingButton(false);
    return Promise.resolve();

  };

  const uploadPDF_TokenizationRecord = async () => {
    if (!pdfTokenizationRecord) {
      alert('Please upload the tokenization act')
      return;
    }
    setIsLoadingButton(true);
    const client = new NFTStorage({ token: API_KEY });
    setNFTname(inputText);
    const metadataObjects = [];
    
    const metadata = await client.store({
      name: name, 
      description: 'Tokenization record of ' + name ,
      image: new File(
        [pdfTokenizationRecord],
        'TokenizationRecord.pdf',
        { type: 'application/pdf' }
      ),
    });
    
    metadataObjects.push(metadata);
    let metaURL = metadata.data.image.href
    metaURL = metaURL.replace("ipfs://", "https://ipfs.io/ipfs/")
    console.log(metaURL)
    setPdfTokenizationRecordURL(metaURL)
    setIsLoadingButton(false);
    return Promise.resolve();

  };

  const uploadPDF_LandRegistry = async () => {
    if (!landRegistry) {
      alert('Please upload the cadastral note')
      return;
    }
    setIsLoadingButton(true);
    const client = new NFTStorage({ token: API_KEY });
    setNFTname(inputText);
    const metadataObjects = [];
    
    const metadata = await client.store({
      name: name, 
      description: 'Tokenization record of ' + name,
      description2: 'a',
      image: new File(
        [landRegistry],
        'LandRegistry.pdf',
        { type: 'application/pdf' }
      ),
    });
    
    metadataObjects.push(metadata);
    let metaURL = metadata.data.image.href
    metaURL = metaURL.replace("ipfs://", "https://ipfs.io/ipfs/")
    console.log(metaURL)
    setLandRegistryURL(metaURL)
    setIsLoadingButton(false);
    return Promise.resolve();

   
  };

  const uploadVideo = async () => {
    if (!video) {
      alert('Please upload a video')
      return;
    }
    setIsLoadingButton(true);
    const client = new NFTStorage({ token: API_KEY });
    setNFTname(inputText);
    const metadataObjects = [];
  
    const price = initialPrice; // Add your custom description here
  
    const videoCID = await client.storeBlob(new File([video], "video.mp4", { type: "video/mp4" }));
  
    const customMetadata = {
      name: name,
      description: description,
      price: price,
      image: `ipfs://${videoCID}`, // Replace with the actual CID for the video
    };
  
    const metadataBlob = new Blob([JSON.stringify(customMetadata)], { type: "application/json" });
  
    const metadataFile = new File([metadataBlob], "metadata.json", { type: "application/json" });
  
    const metadataCID = await client.storeBlob(metadataFile);
  
    const metadataURL = `https://ipfs.io/ipfs/${metadataCID}`;
  
    console.log(metadataURL);
    setMetadataURLs([metadataURL]);
    console.log(metadataURL);
    console.log(`https://ipfs.io/ipfs/${videoCID}`); // log the URL of the uploaded Video file to the console
    setIsLoadingButton(false);
   
    return metadataURL;
    
  };
  
  
  
  
  const mint = (metadataURLResult) => {
      setClaimingNft(true);
      console.log(metadataURLResult)
      let cost = CONFIG.WEI_COST;
      let address = blockchain.account
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalCostWei = String(cost * mintAmount);
      let totalGasLimit = String(gasLimit);
      const maxFeePerGas = 70e9
      const maxPriorityFeePerGas =  40e9
      console.log("Cost: ", totalCostWei);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Check the transaction on your MetaMask`);
      
      blockchain.smartContract.methods
        .safeMint(metadataURLResult, pdfTokenizationRecordURL, propertyDeedURL, landRegistryURL)
        .send({
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
        })
        .once("error", (err) => {
          console.log(err);
          setFeedback("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
          );
          setClaimingNft(false);
          setSuccess(true)
          dispatch(fetchData(blockchain.account));
        });
  };

  const getData = async () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };
  
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  
  useEffect(async() => {
    const getConfigAndData = async () => {
      await getConfig();
      await getData();
    };
    getConfigAndData();
  }, []);

  if (success) {
    return (
      <div className="App">
        <header className="header">
          <a className="logo" href="https://prmut.com" t>
          <img className='logo-image' src={logo}></img>
            <p className="header-text">PRMUT</p>
            
          </a>
          <h1 className="title">YOUR APARTMENT IS NOW ON THE BLOCKCHAIN</h1>
          <a href="https://prmut.com">
            <button className="button" style={{marginTop: '20px'}} >
              <p className="paragraph" >CHECK</p>
            </button>
          </a>
          {isConnected ? (
            <button
              className="connect-button"
              type="submit"
              onClick={(e) => {
                disconnectBut(false);
              }}
            >
              <p className="paragraph">{connectText}</p>
            </button>
          ) : (
            <button
              className="connect-button"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
                connectBut(true);
              }}
            >
              <p className="paragraph">{connectText}</p>
            </button>
          )}
        </header>
      </div>
    );
  } else {
  return (
    <div className="App">
      {success && (
        <header className="header">
           <a className="logo" href="https://prmut.com" t>
          <img className='logo-image' src={logo}></img>
            <p className="header-text">PRMUT</p>
            
          </a>
          <h1 className="title">YOUR APARTMENT IS NOW ON THE BLOCKCHAIN</h1>
          <a href="https://prmut.com">
            <button className="button" >
              <p className="paragraph">START</p>
            </button>
          </a>
          {isConnected ? (
            <button
              className="connect-button"
              type="submit"
              onClick={(e) => {
                disconnectBut(false);
              }}
            >
              <p className="paragraph">{connectText}</p>
            </button>
          ) : (
            <button
              className="connect-button"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
                connectBut(true);
              }}
            >
              <p className="paragraph">{connectText}</p>
            </button>
          )}
        </header>
        
      )}
      {started ? (
        
        <div className="body">
          <header className="header">
          <a className="logo" href="https://prmut.com" t>
          <img className='logo-image' src={logo}></img>
            <p className="header-text">PRMUT</p>
            
          </a>
          {isConnected ? (
            <button
              className="connect-button"
              type="submit"
              onClick={(e) => {
                disconnectBut(false);
              }}
            >
              <p className="paragraph">{connectText}</p>
            </button>
          ) : (
            <button
              className="connect-button"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
                connectBut(true);
              }}
            >
              <p className="paragraph">{connectText}</p>
            </button>
          )}
        </header>
          <div className="form">
            <div className="form-input">
              <form>
                <div>
                  <h2 className="form-title">NAME OF THE ASSET</h2>
                  <input type="text" value={name} onChange={handleNameChange} />
                </div>
                <div>
                  <h2 className="form-title">ADDRESS</h2>
                  <input
                    type="text"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </div>
                <div>
                  <h2 className="form-title">PRICE</h2>
                  <input
                    type="text"
                    value={initialPrice}
                    onChange={handlePriceChange}
                  />
                </div>
               
                <div className="video-container">
                    {videoURL && (
                      <div style={{ flex: 1 }}>
                        <video
                          src={videoURL}
                          controls
                          style={{ width: "100%", borderRadius: "10px" }}
                        />
                      </div>
                    )}
                  </div>
              </form>
              
            </div>
            <div style={{display: 'flex', flexDirection:'row'}}>
                <div>
                  <h2 className="form-title">PROPERTY DEED</h2>
                  <input
                    className={buttonClassName3}
                    style={{width:'214px'}}
                    id="pdf-input"
                    type="file"
                    onChange={handlePropertyDeed}
                  />
                </div>
                <div>
                  <h2 className="form-title">LAND REGISTRY</h2>
                  <input
                  style={{width:'214px'}}
                    className={buttonClassName}
                    id="pdf-input"
                    type="file"
                    onChange={handleLandRegistry}
                  />
                </div>
                <div>
                  <h2 className="form-title">TOKENIZATION RECORD</h2>
                  <input
                    className={buttonClassName4}
                    
                    id="pdf-input"
                    type="file"
                    onChange={handleTokenizationRecord}
                  />
                </div>
                </div>
                <div >
                  <h2 className="form-title">UPLOAD VIDEO</h2>
                  <input
                  
                    className={buttonClassName2}
                    id="file-input"
                    type="file"
                    onChange={handleVideoUpload}
                  />
                  
                </div>
          </div>
          
          
          <button className="mint-button" onClick={(e) => {e.preventDefault(); uploadPDF_LandRegistry(); uploadPDF_PropertyDeed(); uploadPDF_TokenizationRecord(); uploadVideo()}}>
          { propertyDeedURL && landRegistryURL && pdfTokenizationRecordURL && video  ? 'CONFIRM' : confirmText }
          </button>
           
          <button className="mint-button2" onClick={handleUploadsAndMint}>
            { claimingNft  ? 'PLEASE WAIT' : 'MINT' }
          </button>
          <div className="space"></div>
        </div>
      ) : (
        <div className="body">
          <header className="header">
          <a className="logo" href="https://prmut.com">

          <img className='logo-image' src={logo}></img>
            <p className="header-text">PRMUT</p>
          </a>
          {isConnected ? (
            <button
              className="connect-button"
              type="submit"
              onClick={(e) => {
                disconnectBut(false);
              }}
            >
              <p className="paragraph">{connectText}</p>
            </button>
          ) : (
            <button
              className="connect-button"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                dispatch(connect());
                getData();
                connectBut(true);
              }}
            >
              <p className="paragraph">{connectText}</p>
            </button>
          )}
        </header>
          <h1 className="title">TOKENIZE YOUR APARTMENTS</h1>
          <div className="buttons">
          <button className="button" onClick={startButton}>
            <p>START</p>
          </button>
          </div>
        </div>
      )}
    </div>
  );
      }  }


export default App;
